<template>
  <div class="pb-7 my-6">
    <v-data-table
      :headers="tableHeader"
      :items="data"
      :search="search"
      :loading="loading"
      fixed-header
      :items-per-page=5
      :footer-props="{showFirstLastPage: true}"
      @click:row="(row)=>rowAction(row)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-icon class="mr-2" @click.stop="refresh">
            mdi-refresh
          </v-icon>
          <v-toolbar-title>{{tableTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-dialog
            @click:outside="editedItem = null"
            v-model="dialog"
            overlay-opacity="0.7"
          >
            <template v-if="allowCreation" v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2 ml-5"
                v-bind="attrs"
                v-on="on"
              >
                New {{ newBtn }}
              </v-btn>
            </template>
            <slot v-bind:editedItem="editedItem" v-bind:close="close">
              <CreateEditModal
                v-if="fields"
                :formTitle="formTitle"
                :url="url"
                :fields="fields"
                :editedItem='editedItem'
                :close="close"
                @tableUpdated="refresh"
                @modalError="errorHandling"
                style="width:600px"
              />
            </slot>
          </v-dialog>

          <v-dialog @click:outside="editedItem = null" v-model="deleteModal" max-width="500px">
            <delete-modal :close="close" :url="url" :noIdMode="noIdMode" :item="editedItem" @tableUpdated="refresh"/>
          </v-dialog>

        </v-toolbar>
      </template>

      <template #item.rowClick><v-icon style="cursor: pointer">mdi-cursor-default-click-outline</v-icon></template>
      <!--slots for date time formats-->
      <template #item.start="{ item }"> {{ item.start | moment }}</template>
      <template #item.end="{ item }"> {{ item.end | moment }}</template>
      <template #item.created="{ item }"> {{ item.created | moment }}</template>
      <template #item.updated="{ item }"> {{ item.updated | moment }}</template>
      <template #item.modified="{ item }"> {{ item.modified | moment }}</template>
      <!--custom slot for websites-->
      <template #item.website="{ item }">
        <v-icon small @click.stop="openLink(item.website)">{{item.website.length ? "mdi-open-in-new" : "mdi-minus"}}</v-icon>
      </template>
      <!--custom template for full_name-->
      <template #item.full_name="{ item }"> {{ item.first_name }} {{ item.last_name }}</template>
      <!--custom template for coordinates-->
      <template #item.full_coordinates="{ item }"> {{ item.latitude }}, {{ item.longitude }}</template>
      <!--custom template for is_virtual-->
      <template #item.is_virtual="{ item }"> {{ item ? 'Virtual' : 'Hybrid' }}</template>

      <template #item.actions="{ item }">
        <v-icon
          class="mr-4"
          @click.stop="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template #item.action="{ item }">
        <v-icon
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template #item.enabled="{ item }">
        <v-icon
          small
          @click.stop="$emit('block', item)"
          :color="item.enabled ? 'green' : 'red'"
        >
          {{ item.enabled ? 'mdi-block-helper' : 'mdi-checkbox-multiple-marked-circle-outline'}}
        </v-icon>
      </template>

    </v-data-table>
    <i v-show="note" class="ml-3 grey--text"><sup>*</sup> {{note}} </i>
  </div>
</template>

<script>
import moment from 'moment'
import DeleteModal from '../deleteModal/delete-modal'
import CreateEditModal from '../createEditModal/create-edit-modal'

export default {
  name: 'Table',
  props: ['objectName', 'data', 'fields', 'tableHeader', 'url', 'noIdMode', 'refresh', 'rowClick', 'allowCreation', 'loading', 'note'],
  components: {
    CreateEditModal,
    DeleteModal
  },
  data: () => ({
    search: '',
    columns: [],
    dialog: false,
    deleteModal: false,
    editedItem: null,
    editedIndex: -1
  }),
  created () {
    this.editedItem = null
  },
  computed: {
    // objectName () {
    //   return this.title.slice(0, -1).toLowerCase()
    // },

    tableTitle () {
      return this.objectName[0].toUpperCase() + this.objectName.slice(1)
    },

    newBtn () {
      return this.objectName.slice(0, -1)
    },

    formTitle () {
      return this.editedItem ? `Edit ${this.newBtn}` : `New ${this.newBtn}`
    }

  },
  filters: {
    moment: function (date) {
      return moment.parseZone(date).format('MMMM Do YYYY, h:mm a')
    }
  },
  watch: {
    // dialog (val) {
    //   val || this.close()
    // },
    // dialogDelete (val) {
    //   val || this.closeDelete()
    // }
  },

  methods: {

    rowAction (item) {
      // eslint-disable-next-line no-unused-expressions
      this.rowClick ? this.rowClick(item, this.objectName) : null
    },
    editItem (item) {
      this.editedItem = item
      this.dialog = true
    },

    deleteItem (item) {
      this.deleteModal = true
      this.editedItem = item
      console.log('item to delete', this.editedItem)
    },

    close () {
      this.editedItem = null
      this.dialog = false
      this.deleteModal = false
    },

    openLink (link) {
      console.log(link)
      window.open(`${link}`, '_blank')
    },

    errorHandling (error) {
      console.log(error)
      // TODO - snackbar with error
    }
  }
}
</script>

<style lang="scss">
</style>
