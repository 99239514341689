<template>
  <Preloader v-if="loading" class="my-auto" style="height: 100vh"/>
  <div v-else>
    <PageTitle :title="event.name">
<!--      BTN TO REAL-TIME EVENT PAGE-->
<!--      <v-btn-->
<!--        icon-->
<!--        :to="`/admin/realtime/${event.id}`"-->
<!--        color="grey"-->
<!--        x-large-->
<!--      >-->
<!--        <v-icon>mdi-play</v-icon>-->
<!--      </v-btn>-->
    </PageTitle>

    <v-container>
      <v-tabs grow>
        <v-tab>About</v-tab>
        <v-tab>Sponsors</v-tab>
        <v-tab>Villages</v-tab>
        <v-tab>Registrations</v-tab>
        <v-tab>Talks</v-tab>
        <v-tab>Schedule</v-tab>
        <v-tab>After Event</v-tab>

        <v-tab-item>
          <EventInfo :event="event" :loading="loading"/>
          <CodesManagement/>
          <TicketTypes/>
          <Table
            objectName="tracks"
            :data="tracks"
            :fields="tracksFields"
            :tableHeader="tableHeader('Tracks')"
            :url='`/event/${event.id}/track`'
            :refresh="getEventTracks"
            :allowCreation=true
            :loading="isTracksLoading"
          />
          <SponsorshipPackages/>
          <!--          <Analytics/>-->
        </v-tab-item>
        <v-tab-item>
          <!--          FOR FUTURE
                    <Table
                      v-slot="{ editedItem, close }"
                      objectName="sponsors"
                      :data="sponsors"
                      :fields="sponsorFields"
                      :tableHeader="tableHeader('Sponsors')"
                      :url='`admin/p/event/pid/${event.id}/e/sponsor`'
                      :refresh="getEventSponsors"
                      :allowCreation=true
                      :loading="isSponsorsLoading"
                    >
                      <SponsorModal
                        :item='editedItem'
                        :close="close"
                        @tableUpdated="getEventSponsors"
                        style="width: 80vw; height: 100vh"
                      />
                    </Table>-->
          <Table
            objectName="sponsors"
            :data="sponsors"
            :fields="sponsorFields"
            :tableHeader="tableHeader('Sponsors')"
            :url='`/admin/event/${event.id}/sponsorship`'
            :refresh="getEventSponsors"
            :allowCreation=true
            :rowClick="rowClick"
            :loading="isSponsorsLoading"
          />
        </v-tab-item>
        <v-tab-item>
          <Table
            objectName="villages"
            :data="villages"
            :fields="villageFields"
            :tableHeader="tableHeader('Villages')"
            :url='`/event/${event.id}/village`'
            :refresh="getEventVillages"
            :allowCreation=true
            :loading="isVillagesLoading"
          />
        </v-tab-item>
        <v-tab-item>
          <RegistrationsDownload/>
          <AttendeesTable/>
        </v-tab-item>
        <v-tab-item>
          <Talks :tracks="tracks"/>
        </v-tab-item>
        <v-tab-item>
          <Preloader v-if="isTracksLoading"/>
          <Schedule :tracks="tracksList"/>
        </v-tab-item>
        <v-tab-item>
          <AttendeesDownload/>
          <SendCPE/>
        </v-tab-item>
      </v-tabs>

    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import Preloader from '~src/components/common//preloader/Preloader'
import Table from '~src/components/common/table/Table'

import RegistrationsDownload from './event/RegistrationsDownload'
import AttendeesDownload from './event/AttendeesDownload'
import SendCPE from './event/SendCPE'
import Schedule from './event/schedule/Schedule'
import EventInfo from './event/EventInfo'
import AttendeesTable from './event/AttendeesTable'
import TicketTypes from './event/TicketTypes'
import SponsorshipPackages from './event/SponsorshipPackages'
import Talks from './event/SubmissionsAndTalks'
import CodesManagement from './event/CodesManagement'
// import Analytics from './event/Analytics'
// import Pools from './event/pools/Pools'
// import SponsorModal from './event/sponsors/sponsor-create-edit-modal'

export default {
  name: 'EventPage',
  components: {
    CodesManagement,
    Talks,
    SponsorshipPackages,
    TicketTypes,
    AttendeesTable,
    AttendeesDownload,
    RegistrationsDownload,
    SendCPE,
    EventInfo,
    // SponsorModal,
    // Pools,
    // Analytics,
    Schedule,
    PageTitle,
    Preloader,
    Table
  },
  props: {},

  data () {
    return {
      loading: false,
      isSponsorsLoading: false,
      isVillagesLoading: false,
      isTracksLoading: false,
      tableHeaders: jsonHeaders,
      event: undefined,
      events: undefined,
      sponsors: undefined,
      villages: undefined,
      tracks: undefined,
      tracksList: null,
      sponsorFields: null,
      villageFields: null,
      tracksFields: null
    }
  },
  computed: {
    eventId () {
      return this.$router.currentRoute.params.id
    }
  },
  created () {
    this.getEvent()
    this.getEventSponsors()
    this.getEventVillages()
    this.getEventTracks()
    this.getEventSponsorsFields()
    this.getEventVillagesFields()
    this.getEventTracksFields()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    tableHeader (title) {
      return jsonHeaders[title]
    },
    rowClick (item, objectName) {
      this.$router.push(`/admin/events/${this.eventId}/${objectName}/${item.id}`)
    },
    getEvent: function () {
      this.loading = true
      this.GET({ route: `event/${this.eventId}` })
        .then(({ data }) => {
          this.event = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getEventSponsors: function () {
      this.isSponsorsLoading = true
      this.GET({ route: `admin/event/${this.eventId}/sponsorship/` })
        .then(({ data }) => {
          // console.log('getEventSponsors', data)
          this.sponsors = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isSponsorsLoading = false
        })
    },
    getEventVillages: function () {
      this.isVillagesLoading = true
      this.GET({ route: `event/${this.eventId}/village/` })
        .then(({ data }) => {
          this.villages = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isVillagesLoading = false
        })
    },
    getEventTracks: function () {
      this.isTracksLoading = true
      this.GET({ route: `event/${this.eventId}/track` })
        .then(({ data }) => {
          // console.log('get Event Tracks', data.data)
          this.tracks = data.data
          this.tracksList = Array.from(this.tracks, track => track.id)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isTracksLoading = false
        })
    },
    getEventSponsorsFields: function () {
      this.isSponsorsLoading = true
      this.GET({ route: '/fields/sponsorship' })
        .then(({ data }) => {
          // console.log(' fields Sponsors', data)
          this.sponsorFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isSponsorsLoading = false
        })
    },
    getEventVillagesFields: function () {
      this.isVillagesLoading = true
      this.GET({ route: '/fields/village' })
        .then(({ data }) => {
          // console.log(' village Fields', data)
          this.villageFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isVillagesLoading = false
        })
    },
    getEventTracksFields: function () {
      this.isTracksLoading = true
      this.GET({ route: '/fields/track' })
        .then(({ data }) => {
          // console.log(' Event Tracks Fields', data)
          this.tracksFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isTracksLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
</style>
