<template>
  <div>
    <Preloader v-if="loading" class="mt-16"/>
    <div v-else class="mt-6 d-flex">
      <v-img
        v-if="event.background"
        :src="event.background"
        :lazy-src="event.background"
        aspect-ratio="1"
        class="mr-5"
        style="width: 50%;"
        :alt="`${event.name} background`"
      />
      <div>
        <h2 class="mb-6">{{event.name}}</h2>
        <div class="mb-5"><b>Dates: </b>{{event.start | moment}} - {{event.end| moment}}</div>
        <div class="mb-5"><b>Description: </b>{{event.description}}</div>
        <div class="mb-5"><b>Hashtag: </b>{{event.hashtag}}</div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Preloader from '~src/components/common/preloader/Preloader'
import moment from 'moment'

export default {
  name: 'EventInfo',
  components: {
    Preloader
  },
  props: ['event', 'loading'],

  data () {
    return {}
  },
  created () {
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  },
  methods: {
    ...mapActions([
      'POST'
    ]),
    editEvent: function () {
      /*      this.GET({ route: `admin/event/${this.eventId}/sponsors_today` })
          .then(({ data }) => {
            this.sponsorsToday = data.data
            this.loading = false
          })
          .catch(err => {
            console.log(err)
          }) */
    }
  }
}
</script>

<style lang="scss">
</style>
