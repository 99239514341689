<template>
  <v-navigation-drawer
    app
    floating
    permanent
    color="#090909"
    class="sidebar"
  >
    <div class="sidebar__img-wrapper">
        <img :src="logoPath" class="sidebar__logo" alt="sidebar logo"/>
    </div>
    <v-row no-gutters class="sidebar__user">
      <v-col>
        <router-link
          :to="'/profile'"
          class="sidebar__username white--text"
        >
          <v-avatar
            class="mr-3"
            color="grey"
            size="48"
          >
            <img
              v-if="userInfo.image"
              :src="userInfo.image"
              :alt="userName">
            <span v-else-if="userInfo.first_name" class="white--text headline">{{userInfo.first_name.charAt(0)}}{{userInfo.last_name.charAt(0)}}</span>
            <v-icon v-else dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>
          {{ userName }}
        </router-link>
      </v-col>
      <v-col class="sidebar__email mt-3">
        {{ userInfo.email }}
      </v-col>
    </v-row>
    <v-row v-if="isMenuLoading" justify="center">
      <v-col>
        <v-progress-circular
          indeterminate
          width="1"
          color="#ffffff"
        />
      </v-col>
    </v-row>
    <v-expansion-panels
      v-else
      v-model="openedMenuItem"
      multiple
      tile
    >
      <v-expansion-panel v-for="menuList in menuReady" :key="menuList.id">
        <v-expansion-panel-header class="sidebar__role">
          <v-icon class="sidebar__icon">{{menuList.icon}}</v-icon>
          {{menuList.title}}
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-for="item in menuList.items"
          :key="item.id"
          class="sidebar__menu-item"
        >
          <v-icon class="sidebar__icon">{{item.icon}}</v-icon>
          <router-link
            v-if="isAdmin"
            :to="`/admin#${item.id}`"
            class="sidebar__anchor"
          >
            {{item.title}}
          </router-link>
          <router-link
            v-else
            :to="getLink(item)"
            class="sidebar__link"
          >
            {{item.title}}
          </router-link>

          <!--            <v-expansion-panel v-if="item.is_delimiter" class="pa-0">-->
          <!--              <v-expansion-panel-header class="sidebar__role pa-0">-->
          <!--                <v-icon class="sidebar__icon">{{item.icon}}</v-icon>-->
          <!--                {{item.title}}-->
          <!--              </v-expansion-panel-header>-->
          <!--              <v-expansion-panel-content >-->
          <!--                <div v-for="subItem in item.items" :key="subItem.id">-->
          <!--                  <v-icon class="sidebar__icon">{{subItem.icon}}</v-icon>-->
          <!--                  <a-->
          <!--                    v-if="isAdmin"-->
          <!--                    :href="`/admin#${subItem.id}`"-->
          <!--                    class="sidebar__anchor"-->
          <!--                  >-->
          <!--                    {{subItem.title}}-->
          <!--                  </a>-->
          <!--                  <router-link-->
          <!--                    v-else-->
          <!--                    :to="getLink(subItem)"-->
          <!--                    class="sidebar__link"-->
          <!--                  >-->
          <!--                    {{subItem.title}}-->
          <!--                  </router-link>-->
          <!--                </div>-->
          <!--              </v-expansion-panel-content>-->
          <!--            </v-expansion-panel>-->

          <!--          <div v-else>-->
<!--          <v-icon class="sidebar__icon">{{item.icon}}</v-icon>-->
<!--          <a-->
<!--            v-if="isAdmin"-->
<!--            :href="`/admin#${item.id}`"-->
<!--            class="sidebar__anchor"-->
<!--          >-->
<!--            {{item.title}}-->
<!--          </a>-->
<!--          <router-link-->
<!--            v-else-->
<!--            :to="getLink(item)"-->
<!--            class="sidebar__link"-->
<!--          >-->
<!--            {{item.title}}-->
<!--          </router-link>-->
          <!--          </div>-->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      outlined
      block
      :loading="logoutBtnLoading"
      color="#eee"
      class="sidebar__logout"
      @click="logOut"
    >
      Log Out
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import config from '~public/config/config'

export default {
  name: 'SideBar',
  props: {},
  components: {},
  data: () => ({
    logoutBtnLoading: false,
    config,
    menu: [],
    menuReady: [],
    openedMenuItem: 0,
    // openedMenuItem: [0, 1],
    isMenuLoading: false
    // location: ''
  }),
  async created () {
    // this.location = window.location.href.split('/admin')[0]
    this.isMenuLoading = true

    await this.getCurrentUser()
      .then(result => {
        this.setCognitoUsername(result)
      })
    await this.getCurrentUserGroups()
      .then(result => {
        this.setCognitoGroups(result)
      })
    this.getUserInfo()
      .then(result => {
        this.setUserInfo(result)
      })

    // don't know how to do "role" variable if user will have multiple roles
    // because don't know how the request will look like
    const role = 'admin'
    /*    let role = ''
    if (this.isAdmin) role = 'admin'
    if (this.isModerator) role = 'moderator'
    if (this.isSponsor) role = 'sponsor'
    if (this.isSpeaker) role = 'speaker'

    this.getUserAcl(role, this.username) */
    this.getMenuItems(role).finally(() => {
      this.isMenuLoading = false
    })
  },
  computed: {
    ...mapGetters({
      baseUrl: 'currentBaseUrl',
      username: 'getCognitoUsername',
      isAdmin: 'getAdmin',
      isModerator: 'getModerator',
      isSponsor: 'getSponsor',
      isSpeaker: 'getSpeaker',
      userInfo: 'getUserInfo'
    }),
    logoPath () {
      return require('@/assets/img/logo.png')
    },
    userName () {
      return this.userInfo.first_name || this.userInfo.last_name
        ? `${this.userInfo.first_name || ''} ${this.userInfo.last_name || ''}`
        : this.username
    }
  },
  methods: {
    ...mapMutations([
      'setUserAcl',
      'setCognitoUsername',
      'setCognitoGroups',
      'setUserInfo',
      'setSponsorInfo'
    ]),
    ...mapActions([
      'GET',
      'signOut',
      'getCurrentUser',
      'getCurrentUserGroups'
    ]),
    logOut () {
      this.logoutBtnLoading = true
      this.signOut()
      this.setUserAcl({})
      this.setCognitoUsername('')
      this.setCognitoGroups([])
      this.setUserInfo({})
      this.setSponsorInfo({})

      this.$router.push('/login')
    },
    // getUserAcl (role, username) {
    //   return this.GET({ route: `${role}/p/user/pid/${username}/e/useracl/eid/${username}` })
    //     .then(({ data }) => {
    //       this.setUserAcl(data.data.entities)
    //     })
    // },
    getMenuItems (role) {
      return this.GET({ route: `/admin/${role === 'admin' ? '' : role + '_'}menu_item` })
        .then(({ data }) => {
          console.log('getMenuItems', data.data)
          this.menu = data.data
          this.menuReady = this.getmenuReady()
        })
    },
    getmenuReady () {
      let menuReady = this.menu

      if (this.isSponsor) {
        const sponsorMenu = this.menu[0]
        const sponsorRemaining = this.menu.slice(1)
        sponsorMenu.items.splice(1, 0, ...sponsorRemaining)
        menuReady = [sponsorMenu]
      }

      return menuReady
    },
    getLink (item) {
      let eventUrl
      if (this.isSponsor) {
        eventUrl = item.icon.includes('login')
          ? `/upcoming/${item.id}`
          : `/past/${item.id}`
      } else {
        eventUrl = `/${item.id}`
      }

      // to fake last event
      // const eventUrl = `/past/spo2020`

      const url = item.url ? item.url : eventUrl
      return item.title === 'Blog' ? url : `${this.isSponsor ? '/sponsor' : '/speaker'}${url}` // todo remove !!!
    },
    getUserInfo () {
      return this.GET({ route: `/user/${this.username}` })
        .then(({ data }) => data.data)
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .sidebar {
    padding: 16px 16px 24px;
    color: #fff;

    &__logout {
      margin-top: auto;
      flex-grow: 0;
    }

    &__img-wrapper {
      margin-bottom: 24px;
      text-align: center;
    }

    &__logo {
      width: 100%;
    }

    &__user {
      flex-direction: column;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      margin-bottom: 16px;
    }

    &__username {
      text-decoration: none;
      font-size: 20px;
      line-height: 23px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    &__email {
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      margin-bottom: 16px;
      color: rgba(255, 255, 255, 0.6);
    }

    &__link,
    &__anchor {
      color: inherit !important;
      text-decoration: none;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    &__role {
      .sidebar__icon {
        flex-grow: 0 !important;
      }
    }

    &__icon {
      margin-right: 16px;

      &.theme--light.v-icon {
        color: #fff;
      }
    }

    &__menu-item {
      padding: 4px 0 4px 8px;

      .v-expansion-panel-content__wrap {
        display: flex;
        align-items: center;
      }
    }

    .theme--light.v-expansion-panels .v-expansion-panel {
      background-color: #090909;
      color: #fff;
    }

    .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
      color: #fff;
    }

    .v-expansion-panel::before {
      box-shadow: none;
    }

    .v-navigation-drawer__content {
      display: flex;
      flex-direction: column;

      * {
        flex-grow: 0;
      }
    }
  }
</style>
